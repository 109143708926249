<!--  -->
<template>
  <div class="ready">
    <h3>您已准备好设置您的主机。</h3>
    <div class="local">
      <p>核实验证</p>
      <p>确认的，你都准备好了</p>
      <p>有时DNS更改可能需要长达24小时才能在世界各地生效。</p>
    </div>
    <div class="footer">
      <button @click="back">后退</button>
      <button @click="successful">完成</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit("nexted", 3);
    },
    successful() {
      this.$router.push({ path: "/setuppage/marketDnsSetting" });
    },
  },
};
</script>

<style lang='scss' scoped >
::v-deep :focus{
  outline: 0;
}
.ready {
  width: 100%;
  margin-top: 110px;
  h3 {
    width: 100%;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #080707;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
  }
  .local {
    width: 60%;
    margin-left: 20%;
    min-height: 90px;
    margin-top: 100px;
    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
    }
  }
  .footer {
    width: 100%;
    height: 73px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #dddbda;
    button {
      outline: none;
      margin-right: 10px;
      border: 1px solid #dedcda;
      border-radius: 3px;
      padding: 10px 22px;
      cursor: pointer;
      background: #ffffff;
    }
  }
}
</style>

<!--  -->
<template>
  <div class="blog">
    <h3>指定您的内容的托管位置</h3>
    <div class="local">
      <p>{{ name }}</p>
      <div class="inputs">
        <input type="text" v-model="title" />
        <span>·</span>
        <input type="text" v-model="domain" class="h6" />
        <span>·</span>
        <h5>com</h5>
        <div class="popver">
          <p>
            子域
            <el-tooltip :content="tipone" placement="top">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-prompt"></use>
              </svg>
            </el-tooltip>
          </p>
          <p>
            品牌域名
            <el-tooltip :content="tiptwo" placement="top">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-prompt"></use>
              </svg>
            </el-tooltip>
          </p>
          <p>
            顶级域名
            <el-tooltip :content="tipthree" placement="top">
              <svg class="icon" aria-hidden="true">
                <use href="#icon-prompt"></use>
              </svg>
            </el-tooltip>
          </p>
        </div>
      </div>
    </div>
    <h2>
      想要链接跟域？<span>学习怎样</span>
      <svg class="icon" aria-hidden="true">
        <use href="#icon-share"></use>
      </svg>
    </h2>
    <div class="footer">
      <button @click="backto">后退</button>
      <button @click="backto">取消</button>
      <button class="next" @click="nexted">下一步</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "博客",
      title: "blog",
      domain: "cloudcc",
      tipone: '子域是域名的第一部分。所以在www.inbound.com中,子域是"www".',
      tiptwo:
        '品牌域是子域和顶级域之间的域的一部分。所以在www.nbound.com中,品牌域是"inbound".',
      tipthree:
        '顶级域是品牌域之后的域的结尾。在www.nbound.com中,顶级域是"com",而在www.inbound.co.uk中是"co.uk"。',
    };
  },
  methods: {
    nexted() {
      this.$emit("nexted", 2);
    },
    backto() {
      this.$router.push({ path: "/setuppage/marketDnsSetting" });
    },
  },
};
</script>

<style lang='scss' scoped >
::v-deep :focus {
  outline: 0;
}
.blog {
  width: 100%;
  margin-top: 110px;
  h3 {
    width: 100%;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #080707;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
  }
  .local {
    width: 60%;
    margin-left: 20%;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 2px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 97px;
    .inputs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .popver {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        top: -50px;
        p {
          min-width: 200px;
          margin-right: 44px;
          display: flex;
          align-items: center;
          img {
            margin-left: 5px;
            width: 18px;
            height: 18px;
          }
        }
      }
      input {
        border: 1px solid #006dcc;
        border-radius: 2px;
        outline: none;
        padding-left: 10px;
        min-width: 200px;
        height: 43px;
      }
      span {
        display: inline-block;
        height: 43px;
        width: 44px;
        line-height: 43px;
        text-align: center;
      }
      .h6 {
        background: #dddbda;
        border-radius: 2px;
        width: 200px;
        height: 45px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #888888;
        letter-spacing: 0;
        padding-left: 10px;
        line-height: 45px;
        border: none;
      }
      h5 {
        background: #dddbda;
        border: 1px solid #888888;
        border-radius: 2px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        text-align: center;
        width: 77px;
        line-height: 43px;
        cursor: pointer;
      }
    }
  }
  h2 {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 17px;
    margin-top: 24px;
    span {
      color: #006dcc;
      cursor: pointer;
    }
    img {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .footer {
    width: 100%;
    height: 73px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #dddbda;
    button {
      outline: none;
      margin-right: 10px;
      border: 1px solid #dedcda;
      border-radius: 3px;
      padding: 10px 22px;
      cursor: pointer;
      background: #ffffff;
    }
    .next {
      background: #006dcc;
      border-radius: 3px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
</style>

<!--  -->
<template>
  <div class="top">
    <div class="steps">
      <p :class="active > 0 ? 'bluep' : ''">
        <i class="el-icon-check"></i> <span>选择域</span>
      </p>
      <div class="hb" :class="active > 1 ? 'hblue' : ''"></div>
      <p :class="active == 2 ? 'borderb' : active > 2 ? 'bluep' : ''">
        <i class="el-icon-check"></i> <span>验证网址</span>
      </p>
      <div class="hb" :class="active >= 3 ? 'hblue' : ''"></div>
      <p :class="active >= 3 ? 'borderb' : ''">
        <i class="el-icon-check"></i> <span>主机设置</span>
      </p>
    </div>
    <Blog v-if="active == 1" @nexted="nexted"></Blog>
    <Validation v-if="active == 2" @nexted="nexted"></Validation>
    <VerifyHome v-if="active == 3" @nexted="nexted"></VerifyHome>
    <Ready v-if="active == 4" @nexted="nexted"></Ready>
  </div>
</template>

<script>
import VerifyHome from "@/views/systemSettings/components/marketSetting/marketSetHost/verifyHome.vue";
import Blog from "@/views/systemSettings/components/marketSetting/marketSetHost/blog.vue";
import Validation from "@/views/systemSettings/components/marketSetting/marketSetHost/validation.vue";
import Ready from "@/views/systemSettings/components/marketSetting/marketSetHost/ready.vue";
export default {
  components: {
    VerifyHome,
    Blog,
    Validation,
    Ready,
  },
  data() {
    return {
      active: 1,
    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    nexted(id) {
      this.active = id;
    },
  },
};
</script>
<style lang='scss' scoped>
.top {
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-y: scroll;
}
.steps {
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  margin-top: 50px;

  p {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #dddbda;
    text-align: center;
    line-height: 30px;
    float: left;
    margin-top: 10px;
    position: relative;
    i {
      color: #ffffff;
    }
    span {
      position: absolute;
      top: 40px;
      left: -15px;
      display: inline-block;
      width: 60px;
      text-align: center;
    }
  }
  p:first-child {
    margin-left: 24%;
  }
  .hb {
    height: 4px;
    width: 25%;
    background: #dddbda;
    float: left;
  }
  .bluep {
    background: #006dcc;
    border: 1px solid #006dcc;
  }
  .hblue {
    background: #006dcc;
  }
  .borderb {
    border: 1px solid #006dcc;
  }
}
</style>
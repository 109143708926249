<!--  -->
<template>
  <div class="blog">
    <h3>您已准备好设置您的主机。</h3>
    <p class="learnTitle">想要跳过分步说明嘛？<a>查看你的DNS记录</a></p>
    <div class="collapse">
      <el-collapse v-model="collapse">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="collapseHead collapse-title">
              <span>1.确认您的内容已准备好上线</span>
              <el-checkbox
                v-model="contentCheckone"
                @click.native="stopDefault($event)"
                >我准备好了</el-checkbox
              >
            </div>
          </template>
          <div>
            在您更新DNS记录后，您的访问者将在访问您的域时看到您的HubSpot内容。您之前发布到该域的任何内容将不再可供访问者使用。
            您在HubSpot上的内容准备好向公众发布了吗？
          </div>
          <div class="pleaseclick">
            此域似乎已拥有来自其他供应商的SSL证书。如果您的想先配置SSL以确保在您的新证书处于活动状态之前没有停机时间，
            <p>请<span>单击此处。</span></p>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="collapseHead collapse-title">
              <span>2.登录到您的DNS提供商</span>
              <el-checkbox
                v-model="contentChecktwo"
                @click.native="stopDefault($event)"
                >我已经登录</el-checkbox
              >
            </div>
          </template>
          <div class="gysnull">
            <p>您的供应商未被识别</p>
            您可以按照以下一般步骤链接您的域或尝试刷新页面以查看是否可以显示针对您的特定DNS提供商的步骤。
          </div>
          <div>
            登录到您的域提供商。如果您不确定如何登陆，请于您的IT团队分享此页面。
          </div>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <div class="collapseHead collapse-title">
              <span>3.转到DNS设置</span>
              <el-checkbox
                v-model="contentCheckthree"
                @click.native="stopDefault($event)"
                >我在哪里</el-checkbox
              >
            </div>
          </template>
          <div>在DNS提供商的主导航菜单中，转到DNS>DNS设置。</div>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title">
            <div class="collapseHead collapse-title">
              <span>4.更新您的DNS记录</span>
              <el-checkbox
                v-model="contentCheckfour"
                @click.native="stopDefault($event)"
                >完毕</el-checkbox
              >
            </div>
          </template>
          <div>将以下记录添加到cloudcc.com的DNS</div>
          <el-table :data="tableDatafour" style="width: 100%" border>
            <el-table-column
              v-for="(item, num) in tableheadfour"
              :key="num"
              :label="item"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="top"
                  trigger="hover"
                  :content="scope.row[num]"
                >
                  <span slot="reference" class="refer">{{
                    scope.row[num]
                  }}</span>
                </el-popover>
                <p v-if="item == '主机名' || item == '价值'">
                  (<span class="copy" @click="copyText(scope.row[num])"
                    >复制</span
                  >)
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title">
            <div class="collapseHead collapse-title">
              <span>5.验证您的DNS更改</span>
            </div>
          </template>
          <div class="awaitdns">
            <p>等待DNS更改</p>
            您的DNS更改尚未完成或仍在处理中，要发布您的内容，请更新这些记录（如果您尚未更新）。如果有，请在几分钟后再次验证他们，看看他们是否有效
          </div>
          <el-table :data="tableDatafive" style="width: 100%" border>
            <el-table-column
              v-for="(item, num) in tableheadfive"
              :key="num"
              :label="item"
            >
              <template slot-scope="scope">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-warning_round"></use>
                </svg>
                <el-popover
                  placement="top"
                  trigger="hover"
                  :content="scope.row[num]"
                >
                  <span slot="reference" class="refer">{{
                    scope.row[num]
                  }}</span>
                </el-popover>
                <p v-if="item == '主机名' || item == '价值'">
                  (<span class="copy">复制</span>)
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="footer">
      <button @click="nexted(2)">后退</button>
      <button @click="backto">取消</button>
      <button class="next" @click="nexted(4)">下一步</button>
    </div>
  </div>
</template>

<script>
import {copyText} from "@/utils/copyInfo"
export default {
  data() {
    return {
      contentCheckone: false,
      contentChecktwo: false,
      contentCheckthree: false,
      contentCheckfour: false,
      collapse: ['1', '2', '3', '4', '5'],
      tableheadfour: ['类型', '主机名', '价值'],
      tableDatafour: [
        {
          0: '名称',
          1: '博客.cloudcc.com',
          2: '89674663.group43.hubsport.ne'
        }
      ],
      tableheadfive: ['类型', '主机名', '价值', ""],
      tableDatafive: [
        {
          0: '名称',
          1: '博客.cloudcc.com',
          2: '89674663.group43.hubsport.ne',
          3: "记录无效"
        }
      ]
    }
  },
  methods: {
    copyText,
    nexted(index) {
      this.$emit('nexted', index)
    },
    backto() {
      this.$router.push(
        { path: '/setuppage/marketDnsSetting' }
      )
    },
    stopDefault(e) {
      e.stopPropagation();
    },

  }
}
</script>

<style lang='scss' scoped >
::v-deep :focus {
  outline: 0;
}
::v-deep .el-table--border th {
  border: none;
  background: #f6f8fa;
}
::v-deep .el-table td {
  border: none;
  height: 80px;
}
.blog {
  width: 100%;
  margin-top: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    width: 100%;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #080707;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
  }
  .learnTitle {
    width: 100%;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
    text-align: center;
  }
  .footer {
    width: 100%;
    height: 73px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #ffffff;
    border-top: 1px solid #dddbda;
    button {
      outline: none;
      margin-right: 10px;
      border: 1px solid #dedcda;
      border-radius: 3px;
      padding: 10px 22px;
      cursor: pointer;
      background: #ffffff;
    }
    .next {
      background: #006dcc;
      border-radius: 3px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
    }
  }
  .collapse {
    width: 60%;
    margin-bottom: 73px;
    .collapseHead {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: bold;
      }
    }
  }
}
.collapse-title {
  flex: 1 0 90%;
  order: 1;
}
.el-collapse-item__header {
  flex: 1 0 auto;
  order: -1;
}
.pleaseclick {
  width: 100%;
  margin-top: 14px;
  background: #ffffff;
  border: 1px solid #dedcda;
  border-radius: 2px;
  padding-left: 20px;
  padding-top: 20px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  span {
    color: #006dcc;
    cursor: pointer;
  }
}
.gysnull {
  width: 100%;
  background: #e5f6f7;
  border: 1px solid #7ed1de;
  border-radius: 2px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  padding-left: 20px;
  line-height: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  p {
    font-weight: bold;
    padding: 0;
    margin-top: 15px;
  }
}
.awaitdns {
  background: #fdedee;
  border: 1px solid #f8a9ad;
  border-radius: 2px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  p {
    font-weight: bold;
    padding: 0;
    margin-top: 15px;
  }
}
.copy {
  cursor: pointer;
  color: #006dcc;
}
.refer {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.warnimg {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  margin-top: -10px;
}
::v-deep .el-table {
  margin-top: 10px;
}
</style>
